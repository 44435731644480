<template>
  <b-form-input
    :id="id"
    type="text"
    :placeholder="placeholder"
    :value="value"
    @input="input"
    @keydown="nombreKeydown($event)"
    @paste="filterOnPaste($event)"
    required
    :state="fullNameState"
  ></b-form-input>
</template>

<script>
const alphabet = 'abcdefghijklmnñopqrstuvwxyz '
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    id: {
        type: String,
        default: ''
    },
    placeholder: {
        type: String,
        default: ''
    }
  },
  data() {
    return {
      fullNameState: true
    }
  },
  methods: {
    input(e) {
      this.$emit("input", e)
    },
    nombreKeydown(e) {
      this.fullNameState = true
      const keysAllowed = [
        " ",
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Tab",
      ]
      const keyPressed = e.key
      if (!/\p{L}/gu.test(keyPressed) && !keysAllowed.includes(keyPressed)) {
        e.preventDefault()
      }
    },
    filterOnPaste(event) {
      try {
        this.fullNameState = true
        const clipboard = event.clipboardData
          ? event.clipboardData
          : window.clipboardData
        const RawPastedText = clipboard.getData("text/plain")
        const pastedText = RawPastedText.toLowerCase()

        let validText = true

        for (let i = 0; i < pastedText.length; i++) {
          if (!alphabet.includes(pastedText[i])) {
            validText = false
            break
          }
        }

        if (!validText) {
          event.preventDefault()
          const filtered = RawPastedText.split("").filter((letter) => {
            return alphabet.includes(letter.toLowerCase())
          })

          this.$emit("input", filtered.join(""))
          this.fullNameState = false
        }
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>

<style></style>
